import {
    ContentLayout,
    Header,
    Link,
    SpaceBetween,
  } from "@cloudscape-design/components";
  import OverviewTable from "./OverviewTable";
  import React, { useEffect } from "react";
  import { generateClient } from "aws-amplify/api";
  import * as queries from "../../graphql/queries";
  
  const View = () => {
    const [invoiceDetails, setInvoiceDetails] = React.useState<any>();
    const client = generateClient();
    const getData = async () => {
      const res = await client.graphql({
        query: queries.listInvoiceData,
      });
      setInvoiceDetails(
        res.data.listInvoiceData?.items?.filter(
          (i) => i.status === ("shipped" || "Shipped")
        )
      );
    };
  
    useEffect(() => {
      getData();
    }, []);
    return (
      <ContentLayout
        header={
          <Header variant="h1" info={<Link variant="info">Info</Link>}>
            Invoices
          </Header>
        }
      >
        {invoiceDetails ? (
          <SpaceBetween size="l">
            <OverviewTable invoiceDetails={invoiceDetails} />
          </SpaceBetween>
        ) : (
          <>Loading Resources...</>
        )}
      </ContentLayout>
    );
  };
  
  export default View;
  