import {
  ContentLayout,
  Grid,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import AppOverview from "./AppOverview";
import React, { useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import * as queries from "../../graphql/queries";
import { instanceHours } from "./bar-chart";
import { networkTraffic } from "./line-chart";
import { BaseStaticWidget } from "./base-static-widget";

const View = () => {
  const [invoiceDetails, setInvoiceDetails] = React.useState<any>();
  const client = generateClient();
  const getData = async () => {
    const res = await client.graphql({
      query: queries.listInvoiceData,
    });
    setInvoiceDetails(
      res.data.listInvoiceData?.items?.filter(
        (i) => i.status === ("shipped" || "Shipped")
      )
    );
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <ContentLayout
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Dashboard
        </Header>
      }
    >
      {invoiceDetails ? (
        <SpaceBetween size="l">
          <AppOverview invoiceDetails={invoiceDetails} />
          <Grid
            gridDefinition={[
              { colspan: { l: 6, m: 6, default: 12 } },
              { colspan: { l: 6, m: 6, default: 12 } },
            ]}
          >
            {[instanceHours, networkTraffic].map((widget, index) => (
              <BaseStaticWidget key={index} config={widget.data} />
            ))}
          </Grid>
        </SpaceBetween>
      ) : (
        <>Loading Resources...</>
      )}
    </ContentLayout>
  );
};

export default View;
