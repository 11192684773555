import * as React from "react";
import Table from "@cloudscape-design/components/table";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import { ExpandableSection, Pagination, TextContent } from "@cloudscape-design/components";
import { InvoiceData } from "../../API";
import Print from "../pdf/invoice-view/Print";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Preferences, TableEmptyState, TableNoMatchState, getTextFilterCounterText } from "../shared/Common";

export default function OverviewTable(props: any) {
  const [preferences, setPreferences] = React.useState({ pageSize: 10 });
  const [selectedItems, setSelectedItems] = React.useState<any>();

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(props.invoiceDetails as InvoiceData[], {
    filtering: {
      empty: <TableEmptyState resourceName="Invoice" />,
      noMatch: (
        <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const formatJsonStringObject = (input: string) => {
    return JSON.parse(input);
  }
  return (
    <>
    <Table
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      columnDefinitions={[
        {
          id: "ck_order_id",
          header: "CK Order Id",
          cell: (item) => <Print invoice={item} />,
          sortingField: "ck_order_id",
          isRowHeader: true,
        },
        {
          id: "merchant_order_id",
          header: "Merchant Order Id",
          cell: (item) => item.merchant_order_id,
          sortingField: "merchant_order_id",
        },
        {
          id: "amount",
          header: "Amount",
          cell: (item) => item.amount,
        },
        {
          id: "capture_status",
          header: "Capture Status",
          cell: (item) => item.capture_status,
        },
        {
          id: "charges",
          header: "Charges",
          cell: (item) => <ExpandableSection headerText={"Total: " + formatJsonStringObject(item.charges as string).grand_total}>
            <TextContent>
              <p>tax: {formatJsonStringObject(item.charges as string).tax}</p>
              <p>shipping: {formatJsonStringObject(item.charges as string).shipping}</p>
              <p>discount_amount: {formatJsonStringObject(item.charges as string).discount_amount}</p>
            </TextContent>
          </ExpandableSection>,
        },
        {
          id: "remote_customer_id",
          header: "Customer Id",
          cell: (item) => item.remote_customer_id,
        },
        {
          id: "status",
          header: "Item Status",
          cell: (item) => item.status,
        },
        {
          id: "Shipping Address",
          header: "Shipping Address",
          cell: (item) => <ExpandableSection headerText={formatJsonStringObject(item.shipping_address as string).address1}>
            <TextContent>
            <p>{formatJsonStringObject(item.shipping_address as string).address1}</p>
            <p>{formatJsonStringObject(item.shipping_address as string).address2}</p>
            <p>{formatJsonStringObject(item.shipping_address as string).city} State: {formatJsonStringObject(item.shipping_address as string).state}</p>
            <p>{formatJsonStringObject(item.shipping_address as string).zip}</p>
            <p>{formatJsonStringObject(item.shipping_address as string).email}</p>
            </TextContent>
          </ExpandableSection>,
        }
      ]}
      enableKeyboardNavigation
      items={items}
      loadingText="Loading resources"
      trackBy="id"
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel="Filter Invoices"
          filteringPlaceholder="Find Invoices"
          filteringClearAriaLabel="Clear"
          countText={getTextFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={true}
        />
      }
      header={
        <Header
        >
          Overview
        </Header>
      }
      stripedRows={true}
    />
    </>
  );
}
