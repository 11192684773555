import {
  ContentLayout,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import React from "react";
import ChargeTable from "./ChargeTable";

const View = () => {
  return (
      <ContentLayout
        header={
          <Header variant="h1" info={<Link variant="info">Info</Link>}>
            Charges
          </Header>
        }
      >
        <SpaceBetween size="l">
          <ChargeTable />
        </SpaceBetween>
      </ContentLayout>
  );
};

export default View;
