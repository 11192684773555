// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { BarChartProps } from '@cloudscape-design/components';

const cpuData = [
  { date: new Date(2020, 8, 16), 'create-order-response': 878, 'capture-shipment': 491, 'transform-order': 284, 'parse-order': 70 },
  { date: new Date(2020, 8, 17), 'create-order-response': 781, 'capture-shipment': 435, 'transform-order': 242, 'parse-order': 96 },
  { date: new Date(2020, 8, 18), 'create-order-response': 788, 'capture-shipment': 478, 'transform-order': 311, 'parse-order': 79 },
  { date: new Date(2020, 8, 19), 'create-order-response': 729, 'capture-shipment': 558, 'transform-order': 298, 'parse-order': 97 },
  { date: new Date(2020, 8, 20), 'create-order-response': 988, 'capture-shipment': 530, 'transform-order': 255, 'parse-order': 97 },
  { date: new Date(2020, 8, 21), 'create-order-response': 1016, 'capture-shipment': 445, 'transform-order': 339, 'parse-order': 70 },
  { date: new Date(2020, 8, 22), 'create-order-response': 987, 'capture-shipment': 549, 'transform-order': 273, 'parse-order': 62 },
  { date: new Date(2020, 8, 23), 'create-order-response': 986, 'capture-shipment': 518, 'transform-order': 341, 'parse-order': 67 },
  { date: new Date(2020, 8, 24), 'create-order-response': 925, 'capture-shipment': 454, 'transform-order': 382, 'parse-order': 68 },
  { date: new Date(2020, 8, 25), 'create-order-response': 742, 'capture-shipment': 538, 'transform-order': 361, 'parse-order': 70 },
  { date: new Date(2020, 8, 26), 'create-order-response': 920, 'capture-shipment': 486, 'transform-order': 262, 'parse-order': 91 },
  { date: new Date(2020, 8, 27), 'create-order-response': 826, 'capture-shipment': 457, 'transform-order': 248, 'parse-order': 76 },
  { date: new Date(2020, 8, 28), 'create-order-response': 698, 'capture-shipment': 534, 'transform-order': 243, 'parse-order': 66 },
  { date: new Date(2020, 8, 29), 'create-order-response': 1003, 'capture-shipment': 523, 'transform-order': 393, 'parse-order': 70 },
  { date: new Date(2020, 8, 30), 'create-order-response': 811, 'capture-shipment': 527, 'transform-order': 353, 'parse-order': 88 },
];

export const cpuDomain = cpuData.map(({ date }) => date);

export const cpuSeries: BarChartProps<Date>['series'] = [
  {
    title: 'create-order-response',
    type: 'bar',
    data: cpuData.map(datum => ({ x: datum.date, y: datum['create-order-response'] })),
  },
  {
    title: 'capture-shipment',
    type: 'bar',
    data: cpuData.map(datum => ({ x: datum.date, y: datum['capture-shipment'] })),
  },
  {
    title: 'transform-order',
    type: 'bar',
    data: cpuData.map(datum => ({ x: datum.date, y: datum['transform-order'] })),
  },
  {
    title: 'parse-order',
    type: 'bar',
    data: cpuData.map(datum => ({ x: datum.date, y: datum['parse-order'] })),
  },
];