import {
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Input,
  SpaceBetween,
  Tabs,
  TopNavigation,
} from "@cloudscape-design/components";

const Login = () => {
  return (
    // <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
    //     <div></div>
    //     <Container>

    //     <FormField label="Login">
    //         <SpaceBetween size='l'>
    //             <Input type='email' value={''} placeholder='Username'/>
    //             <Input type='password' value={''} placeholder='Password'/>
    //         </SpaceBetween>
    //     </FormField>
    //     </Container>
    // </Grid>
    <>
      <SpaceBetween size="xxl">
        <TopNavigation
          identity={{
            href: "#",
            title: "Credit Flexi",
          }}
        />

        <Grid
          gridDefinition={[
            { colspan: { default: 12, s: 4 } },
            { colspan: { default: 12, s: 4 } },
            { colspan: { default: 12, s: 4 } },
          ]}
        >
          <div></div>
          <Box padding={"xl"}>
            <Tabs
              tabs={[
                {
                  label: "Login",
                  id: "login",
                  content: (
                    <Container
                      fitHeight={true}
                      media={{
                        content: (
                          <img
                            src="https://alidropship.com/wp-content/uploads/2020/03/Dropship-Single.jpg"
                            alt="placeholder"
                          />
                        ),
                        height: 200,
                        position: "top",
                      }}
                    >
                      <Box textAlign="right">
                        <SpaceBetween size="l">
                          <FormField label="Login"></FormField>
                          <Input
                            type="email"
                            value={""}
                            onChange={() => {}}
                            placeholder="Username"
                          />
                          <Input
                            type="password"
                            value={""}
                            onChange={() => {}}
                            placeholder="Password"
                          />
                          <Button variant="primary">Login</Button>
                        </SpaceBetween>
                      </Box>
                    </Container>
                  ),
                },
                {
                  label: "Sign up",
                  id: "signup",
                  content: (
                    <Container
                      fitHeight={true}
                      media={{
                        content: (
                          <img
                            src="https://online.csp.edu/wp-content/uploads/2020/07/What-is-Drop-Shipping.png"
                            alt="placeholder"
                          />
                        ),
                        height: 200,
                        position: "top",
                      }}
                    >
                      <Box textAlign="right">
                        <SpaceBetween size="l">
                          <FormField label="Login"></FormField>
                          <Input
                            type="email"
                            value={""}
                            onChange={() => {}}
                            placeholder="Username"
                          />
                          <Input
                            type="password"
                            value={""}
                            onChange={() => {}}
                            placeholder="Password"
                          />
                          <Input
                            type="password"
                            value={""}
                            onChange={() => {}}
                            placeholder="Confirm Password"
                          />
                          <Button variant="primary">Signup</Button>
                        </SpaceBetween>
                      </Box>
                    </Container>
                  ),
                },
              ]}
            />
          </Box>

          <></>
        </Grid>
      </SpaceBetween>
    </>
  );
};

export default Login;
