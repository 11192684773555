import { SideNavigationProps, TopNavigation, AppLayout, SideNavigation, HelpPanel } from '@cloudscape-design/components';
import I18nProvider from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import { AuthUser } from 'aws-amplify/auth';
import React from 'react'

const LOCALE = "en";

const navItems: SideNavigationProps.Item[] = [
  {
    type: "section-group",
    items: [
      { type: "link", text: "Dashboard", href: "/" },
      { type: "link", text: "Invoices", href: "/invoices" },
      // { type: "link", text: "Charges", href: "/charges" },
      // { type: "link", text: "Consumers", href: "/consumers" },
      // { type: "link", text: "Funding", href: "/funding" },
      // { type: "link", text: "Users", href: "/users" }
    ],
    title: "Home",
  },
  // { type: "divider" },
  // {
  //   type: "section",
  //   text: "Reports",
  //   items: [
  //     { type: "link", text: "Overview", href: "#" },
  //     { type: "link", text: "Rates", href: "#" },
  //     { type: "link", text: "Store Analysis", href: "#" },
  //     { type: "link", text: "Promo Analysis", href: "#" },
  //   ],
  // },
];

const Layout = ({
    children,
    currentUser,
    signOut
  }: Readonly<{
    children: React.ReactNode;
    currentUser: AuthUser | undefined;
    signOut: any
  }>) => {
  return (
    <I18nProvider locale={LOCALE} messages={[messages]}>
      <TopNavigation
        utilities={[
          {
            type: "button",
            iconName: "notification",
            ariaLabel: "Notifications",
            badge: true,
            disableUtilityCollapse: true,
          },
          {
            type: "button",
            text: "Sign Out",
            iconName: "lock-private",
            ariaLabel: "Sign Out",
            onClick: () => {signOut()}
          },
          {
            type: "button",
            text: currentUser?.signInDetails?.loginId,
            iconName: "user-profile"
          },
        ]}
        identity={{
          href: "/",
          title: "",
          logo: {
            src: "/logo.png",
            alt: "Service"
          }
        }}
      />
      <AppLayout
        navigation={<SideNavigation items={navItems} />}
        tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
        content={ children }
      />
    </I18nProvider>
  )
}

export default Layout