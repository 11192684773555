// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import { LineChartProps } from '@cloudscape-design/components';

const networkTrafficData = [
  { date: new Date(1600984800000), 'create-order-response': 63, 'capture-shipment': 40, 'transform-order': 25, 'parse-order': 20 },
  { date: new Date(1600985700000), 'create-order-response': 62, 'capture-shipment': 46, 'transform-order': 23, 'parse-order': 25 },
  { date: new Date(1600986600000), 'create-order-response': 72, 'capture-shipment': 46, 'transform-order': 29, 'parse-order': 28 },
  { date: new Date(1600987500000), 'create-order-response': 79, 'capture-shipment': 40, 'transform-order': 28, 'parse-order': 26 },
  { date: new Date(1600988400000), 'create-order-response': 66, 'capture-shipment': 48, 'transform-order': 25, 'parse-order': 28 },
  {
    date: new Date(1600989300000),
    'create-order-response': 75,
    'capture-shipment': 45,
    'transform-order': 21,
    'parse-order': 107,
  },
  {
    date: new Date(1600990200000),
    'create-order-response': 75,
    'capture-shipment': 49,
    'transform-order': 27,
    'parse-order': 185,
  },
  { date: new Date(1600991100000), 'create-order-response': 70, 'capture-shipment': 44, 'transform-order': 20, 'parse-order': 25 },
  { date: new Date(1600992000000), 'create-order-response': 63, 'capture-shipment': 42, 'transform-order': 29, 'parse-order': 20 },
  { date: new Date(1600992900000), 'create-order-response': 64, 'capture-shipment': 44, 'transform-order': 29, 'parse-order': 23 },
  { date: new Date(1600993800000), 'create-order-response': 79, 'capture-shipment': 47, 'transform-order': 24, 'parse-order': 26 },
  { date: new Date(1600994700000), 'create-order-response': 71, 'capture-shipment': 43, 'transform-order': 28, 'parse-order': 29 },
  { date: new Date(1600995600000), 'create-order-response': 77, 'capture-shipment': 44, 'transform-order': 25, 'parse-order': 20 },
  {
    date: new Date(1600996500000),
    'create-order-response': 129,
    'capture-shipment': 46,
    'transform-order': 29,
    'parse-order': 21,
  },
  {
    date: new Date(1600997400000),
    'create-order-response': 161,
    'capture-shipment': 49,
    'transform-order': 23,
    'parse-order': 22,
  },
  {
    date: new Date(1600998300000),
    'create-order-response': 144,
    'capture-shipment': 46,
    'transform-order': 28,
    'parse-order': 27,
  },
  {
    date: new Date(1600999200000),
    'create-order-response': 162,
    'capture-shipment': 41,
    'transform-order': 21,
    'parse-order': 26,
  },
  {
    date: new Date(1601000100000),
    'create-order-response': 112,
    'capture-shipment': 49,
    'transform-order': 24,
    'parse-order': 20,
  },
  {
    date: new Date(1601001000000),
    'create-order-response': 135,
    'capture-shipment': 45,
    'transform-order': 27,
    'parse-order': 20,
  },
  {
    date: new Date(1601001900000),
    'create-order-response': 103,
    'capture-shipment': 47,
    'transform-order': 21,
    'parse-order': 20,
  },
  {
    date: new Date(1601002800000),
    'create-order-response': 178,
    'capture-shipment': 48,
    'transform-order': 27,
    'parse-order': 22,
  },
  {
    date: new Date(1601003700000),
    'create-order-response': 184,
    'capture-shipment': 46,
    'transform-order': 59,
    'parse-order': 21,
  },
  {
    date: new Date(1601004600000),
    'create-order-response': 79,
    'capture-shipment': 41,
    'transform-order': 175,
    'parse-order': 27,
  },
  { date: new Date(1601005500000), 'create-order-response': 61, 'capture-shipment': 41, 'transform-order': 27, 'parse-order': 25 },
  { date: new Date(1601006400000), 'create-order-response': 64, 'capture-shipment': 43, 'transform-order': 24, 'parse-order': 27 },
  { date: new Date(1601007300000), 'create-order-response': 67, 'capture-shipment': 45, 'transform-order': 25, 'parse-order': 25 },
  { date: new Date(1601008200000), 'create-order-response': 75, 'capture-shipment': 42, 'transform-order': 25, 'parse-order': 22 },
  { date: new Date(1601009100000), 'create-order-response': 63, 'capture-shipment': 43, 'transform-order': 22, 'parse-order': 28 },
  { date: new Date(1601010000000), 'create-order-response': 61, 'capture-shipment': 44, 'transform-order': 29, 'parse-order': 28 },
  { date: new Date(1601010900000), 'create-order-response': 61, 'capture-shipment': 44, 'transform-order': 20, 'parse-order': 25 },
  { date: new Date(1601011800000), 'create-order-response': 60, 'capture-shipment': 42, 'transform-order': 27, 'parse-order': 23 },
  { date: new Date(1601012700000), 'create-order-response': 64, 'capture-shipment': 44, 'transform-order': 20, 'parse-order': 24 },
  { date: new Date(1601013600000), 'create-order-response': 77, 'capture-shipment': 43, 'transform-order': 27, 'parse-order': 24 },
];

export const networkTrafficDomain = [
  networkTrafficData[0].date,
  networkTrafficData[networkTrafficData.length - 1].date,
];

export const networkTrafficSeries: LineChartProps<Date>['series'] = [
  {
    title: 'create-order-response',
    type: 'line',
    valueFormatter: value => value.toLocaleString('en-US'),
    data: networkTrafficData.map(datum => ({ x: datum.date, y: datum['create-order-response'] })),
  },
  {
    title: 'capture-shipment',
    type: 'line',
    valueFormatter: value => value.toLocaleString('en-US'),
    data: networkTrafficData.map(datum => ({ x: datum.date, y: datum['capture-shipment'] })),
  },
  {
    title: 'transform-order',
    type: 'line',
    valueFormatter: value => value.toLocaleString('en-US'),
    data: networkTrafficData.map(datum => ({ x: datum.date, y: datum['transform-order'] })),
  },
  {
    title: 'parse-order',
    type: 'line',
    valueFormatter: value => value.toLocaleString('en-US'),
    data: networkTrafficData.map(datum => ({ x: datum.date, y: datum['parse-order'] })),
  },
];