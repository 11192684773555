import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Charges from "./pages/Charges";
import Consumers from "./pages/Consumers";
import Users from "./pages/Users";
import Funding from "./pages/Funding";
import Layout from "./component/Layout";
import Login from "./component/auth/Login";
import { Amplify } from "aws-amplify";
import awsMobile from "./aws-exports";
import { Authenticator, View, useTheme, Text } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box } from "@cloudscape-design/components";
import Invoices from "./pages/Invoices";

Amplify.configure(awsMobile);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/invoices",
    element: <Invoices />,
  },
  {
    path: "/charges",
    element: <Charges />,
  },
  {
    path: "/consumers",
    element: <Consumers />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/funding",
    element: <Funding />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const App = () => {

  const { tokens } = useTheme();
  const components = {
    Header() {

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <img
            alt="Amplify logo"
            src={"/OriginLogo.png"}
            width="100%"
            height="50%"
          />
        </View>
      );
    },
    Footer() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; 2024 CredFlexi All Rights Reserved
        </Text>
      </View>
      );
    }
  };
  return (
    // <Login />

    <Authenticator variation="default" components={components}>
      {({ signOut, user }) => (
        <Layout currentUser={user} signOut={signOut}>
          <RouterProvider router={router} />
          <Box textAlign="center">
            &copy; 2024 CredFlexi All Rights Reserved
          </Box>
        </Layout>
      )}
    </Authenticator>
  );
};

export default App;
