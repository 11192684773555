/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInvoiceData = /* GraphQL */ `query GetInvoiceData($id: String!) {
  getInvoiceData(id: $id) {
    id
    amount
    capture_status
    cart_items
    charges
    ck_order_id
    merchant_order_id
    remote_customer_id
    remote_id
    shipping_address
    status
    term
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceDataQueryVariables,
  APITypes.GetInvoiceDataQuery
>;
export const listInvoiceData = /* GraphQL */ `query ListInvoiceData(
  $filter: TableInvoiceDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoiceData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      capture_status
      cart_items
      charges
      ck_order_id
      merchant_order_id
      remote_customer_id
      remote_id
      shipping_address
      status
      term
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoiceDataQueryVariables,
  APITypes.ListInvoiceDataQuery
>;
