import {
  ColumnLayout,
  Box,
  Link,
  Container,
} from "@cloudscape-design/components";
import React from "react";

const AppOverview = (props: any) => {
  return (
    <Container>
      <ColumnLayout columns={4} variant="text-grid" minColumnWidth={170}>
        <div>
          <Box variant="awsui-key-label">Total Invoices</Box>
          <Link
            variant="awsui-value-large"
            href="#"
            ariaLabel="Running instances (14)"
          >
            {props.invoiceDetails.length}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Total Amount</Box>
          <Link variant="awsui-value-large" href="#" ariaLabel="Volumes (126)">
            {props.invoiceDetails.reduce(function (prev: any, current: any) {
              return prev + +current.amount;
            }, 0)}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Shipped Orders</Box>
          <Link
            variant="awsui-value-large"
            href="#"
            ariaLabel="Security groups (116)"
          >
            {props.invoiceDetails.filter((i:any) => i.status === 'shipped')?.length}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Returned Orders</Box>
          <Link
            variant="awsui-value-large"
            href="#"
            ariaLabel="Load balancers (28)"
          >
           {props.invoiceDetails.filter((i:any) => i.status === 'returned')?.length}
          </Link>
        </div>
      </ColumnLayout>
    </Container>
  );
};

export default AppOverview;
