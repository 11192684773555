import { BlobProvider } from "@react-pdf/renderer";
import React from "react";
import InvoiceView from "./View";
import { Link } from "@cloudscape-design/components";
import { InvoiceData } from "../../../API";

type Props = {
  invoice: InvoiceData;
};

const Print = ({ invoice }: Props) => {
  return (
    <BlobProvider document={<InvoiceView data={invoice} />}>
      {({ url, blob }) => (
        <Link href={url!} target="_blank">
          {invoice.ck_order_id}
        </Link>
      )}
    </BlobProvider>
  );
};

export default Print;
