import React, { Fragment } from "react";
import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "./logo.png";
import { InvoiceData } from "../../../API";

type Props = {
  data: InvoiceData;
};

type CartItem = {
  merchant_id: string;
  name: string;
  price: number;
  quantity: number;
  sku: string;
  color: string;
  size: string;
  tax: number;
};

const InvoiceView = ({ data }: Props) => {
  const receipt_data = {
    id: data?.id,
    invoice_no: data?.ck_order_id,
    address: "739 Porter Avenue, Cade, Missouri, 1134",
    date: "24-09-2019",
    items: [
      {
        id: 1,
        desc: "do ex anim quis velit excepteur non",
        qty: 8,
        price: 179.25,
      },
      {
        id: 2,
        desc: "incididunt cillum fugiat aliqua Lorem sit Lorem",
        qty: 9,
        price: 107.78,
      },
      {
        id: 3,
        desc: "quis Lorem ad laboris proident aliqua laborum",
        qty: 4,
        price: 181.62,
      },
      {
        id: 4,
        desc: "exercitation non do eu ea ullamco cillum",
        qty: 4,
        price: 604.55,
      },
      {
        id: 5,
        desc: "ea nisi non excepteur irure Lorem voluptate",
        qty: 6,
        price: 687.08,
      },
    ],
  };

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    titleContainer: { flexDirection: "row", marginTop: 24 },

    logo: { width: 90 },

    reportTitle: { fontSize: 16, textAlign: "left" },

    addressTitle: { fontSize: 11, fontStyle: "bold" },

    invoice: { fontWeight: "bold", fontSize: 20 },

    invoiceNumber: { fontSize: 11, fontWeight: "bold" },

    address: { fontWeight: 400, fontSize: 10 },

    theader: {
      marginTop: 20,
      fontSize: 10,
      fontStyle: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: "whitesmoke",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: "whitesmoke",
      borderBottomWidth: 1,
    },

    tbody2: { flex: 2, borderRightWidth: 1 },
  });

  const InvoiceTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.reportTitle}>Essonsories Corp</Text>
      </View>
    </View>
  );

  const Address = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoice}>Invoice </Text>
          <Text style={styles.invoiceNumber}>
            Invoice number: {data?.ck_order_id}{" "}
          </Text>
          <Text style={styles.invoiceNumber}>
            Order number: {data?.merchant_order_id}{" "}
          </Text>
        </View>
        <View>
          <Text style={styles.addressTitle}>16191, Coastal Highway Lewes </Text>
          {/* <Text style={styles.addressTitle}>,</Text> */}
          <Text style={styles.addressTitle}>Delaware, 19958</Text>
        </View>
      </View>
    </View>
  );

  const formatJsonStringObject = (input: string) => {
    return JSON.parse(input);
  };

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ width: "80%", flexDirection: "column" }}>
          <Text style={styles.reportTitle}>Bill to </Text>
          <Text style={styles.address}>
            {formatJsonStringObject(data.shipping_address as string).address1}
          </Text>
          <Text style={styles.address}>
            {formatJsonStringObject(data.shipping_address as string).address2}
          </Text>
          <Text style={styles.address}>
            {formatJsonStringObject(data.shipping_address as string).city}{" "}
            {formatJsonStringObject(data.shipping_address as string).state}
          </Text>
          <Text style={styles.address}>
            {formatJsonStringObject(data.shipping_address as string).zip}
          </Text>
          <Text style={styles.address}>
            {formatJsonStringObject(data.shipping_address as string).email}
          </Text>
        </View>
        <View style={{ width: "20%", flexDirection: "column" }}>
          <Text style={styles.reportTitle}>{"Charges"}</Text>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <Text style={styles.address}>Tax: </Text>
            <Text style={styles.address}>
              {formatJsonStringObject(data.charges as string).tax}
            </Text>
          </View>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <Text style={styles.address}>Shipping: </Text>
            <Text style={styles.address}>
              {formatJsonStringObject(data.charges as string).shipping}
            </Text>
          </View>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <Text style={styles.address}>Discount: </Text>
            <Text style={styles.address}>
              {formatJsonStringObject(data.charges as string).discount_amount}
            </Text>
          </View>
          <View style={{ width: "100%", flexDirection: "row" }}>
            <Text style={styles.address}>Total: </Text>
            <Text style={styles.address}>
              {formatJsonStringObject(data.charges as string).grand_total}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );

  const TableHead = () => (
    <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
      <View style={[styles.theader, styles.theader2]}>
        <Text>SKU</Text>
      </View>
      <View style={styles.theader}>
        <Text>Name</Text>
      </View>
      <View style={styles.theader}>
        <Text>Qty</Text>
      </View>
      <View style={styles.theader}>
        <Text>Amount</Text>
      </View>
    </View>
  );

  const TableRow = (receipt: any) => (
    <Fragment key={receipt.merchant_id}>
      <View style={{ width: "100%", flexDirection: "row" }}>
        <View style={[styles.tbody, styles.tbody2]}>
          <Text>{receipt.sku}</Text>
        </View>
        <View style={styles.tbody}>
          <Text>{receipt.name} </Text>
        </View>
        <View style={styles.tbody}>
          <Text>{Number(receipt.quantity)}</Text>
        </View>
        <View style={styles.tbody}>
          <Text>{(Number(receipt.price) + Number(receipt.tax)).toFixed(2)}</Text>
        </View>
      </View>
    </Fragment>
  );

  const formartCartItems = (input: string | null | undefined) => {
    return JSON.parse(input!) as CartItem[];
  };

  const TableTotal = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text>Total</Text>
      </View>
      <View style={styles.tbody}>
        <Text>
          {formartCartItems(data?.cart_items).reduce(
            (sum, item) => sum + (item.price * item.quantity) + item.tax,
            0
          )}
        </Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle />
        <Address />
        <UserAddress />
        <TableHead />
        {formartCartItems(data?.cart_items).map((receipt: CartItem) => {
          return (
            <Fragment key={receipt.merchant_id}>
      <View style={{ width: "100%", flexDirection: "row" }}>
        <View style={[styles.tbody, styles.tbody2]}>
          <Text>{receipt.sku}</Text>
        </View>
        <View style={styles.tbody}>
          <Text>{receipt.name} </Text>
        </View>
        <View style={styles.tbody}>
          <Text>{Number(receipt.quantity)}</Text>
        </View>
        <View style={styles.tbody}>
          <Text>{(Number(receipt.price) + Number(receipt.tax)).toFixed(2)}</Text>
        </View>
      </View>
    </Fragment>
          );
        })}
        <TableTotal />
      </Page>
    </Document>
  );
};

export default InvoiceView;
